import { FormattedMessage } from 'react-intl';

import logo1 from '../../assets/images/logo1.jpeg';
import logo2 from '../../assets/images/logo2.svg';

const Logos = () => {
  return (
    <div className="logos">
      <div className="logo1">
        <span>
          <FormattedMessage id="Logos.adaptedBy" />
        </span>
        <img src={logo1} alt="" />
      </div>
      <div className="logo2">
        <span>
          <FormattedMessage id="Logos.supportedBy" />
        </span>
        <img src={logo2} alt="" />
      </div>
    </div>
  );
};

export default Logos;
