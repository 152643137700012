const flow = {
  start: {
    yes: 'q3',
    no: 'q1',
  },
  q1: {
    yes: 'r1',
    no: 'q2',
    color: 'green',
  },
  q2: {
    yes: 'r2',
    no: 'r1',
    color: 'blue',
  },
  q3: {
    a1: 'q4',
    a2: 'q11',
    color: 'green',
  },
  q4: {
    yes: 'q5',
    no: 'q9',
    color: 'blue',
  },
  q5: {
    yes: 'q6',
    no: 'q7',
    color: 'blue',
  },
  q6: {
    yes: 'r3',
    no: 'r4',
    color: 'blue',
  },
  q7: {
    yes: 'q8',
    no: 'r5',
    color: 'blue',
  },
  q8: {
    a1: 'r6',
    a2: 'r7',
    color: 'blue',
  },
  q9: {
    yes: 'r8',
    no: 'q10',
    color: 'blue',
  },
  q10: {
    a1: 'r9',
    a2: 'r10',
    color: 'blue',
  },
  q11: {
    a1: 'q16',
    a2: 'q12',
    color: 'green',
  },
  q12: {
    yes: 'q13',
    no: 'q14',
    color: 'green',
  },
  q13: {
    a1: 'r4',
    a2: 'r12',
    color: 'green',
  },
  q14: {
    a1: 'r12',
    a2: 'q15',
    color: 'blue',
  },
  q15: {
    yes: 'r13',
    no: 'r14',
    color: 'blue',
  },
  q16: {
    a1: 'q18',
    a2: 'q17',
    color: 'green',
  },
  q17: {
    yes: 'q14',
    no: 'r15',
    color: 'green',
  },
  q18: {
    a1: 'r17',
    a2: 'r16',
    color: 'green',
  },
};

export default flow;
