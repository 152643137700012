import React from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import config from './config';
import './styles/style.css';
import reportWebVitals from './reportWebVitals';
import store from './store';
import router from './routes/router';

import messages_lv from './translations/lv.json';

const defaultLocale = config.locale;
const language = defaultLocale;
const messages = {
  lv: messages_lv,
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <IntlProvider
      locale={language}
      defaultLocale={defaultLocale}
      messages={messages[language]}
    >
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </IntlProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
