import { useRouteError } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>
        <FormattedMessage id="ErrorPage.oops" />
      </h1>
      <p>
        <FormattedMessage id="ErrorPage.unexpectedError" />
      </p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};

export default ErrorPage;
