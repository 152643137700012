const R7content = () => {
  return (
    <div className="desc">
      <p>
        Par dažādām līdzdalības formām lasi Latvijas Republikas Tiesībsarga
        izdotā brošūrā <br />
        <a
          href="https://www.tiesibsargs.lv/resource/2022-pilsoniska-lidzdaliba/"
          className="pink-text-bg"
        >
          Tavās rokās - pasauli kustināt!
        </a>
      </p>
      <p>
        <a
          href="https://likumi.lv/ta/id/57980-latvijas-republikas-satversme"
          className="pink-text-bg"
        >
          Latvijas Republikas Satversmes
        </a>
        100. pants nosaka “Ikvienam ir tiesības uz vārda brīvību, kas ietver
        tiesības brīvi iegūt, paturēt un izplatīt informāciju, paust savus
        uzskatus. Cenzūra ir aizliegta.”
      </p>
    </div>
  );
};

export default R7content;
