const R11content = () => {
  return (
    <div className="desc">
      <p>
        Sociālā uzņēmējdarbība ir preču ražošana un pakalpojumu sniegšana ar
        vēlmi radīt sociālo ietekmi, izmantojot biznesa metodes. Sociālās
        uzņēmējdarbības mērķis ir risināt kādu sociālu problēmu vai radīt labumu
        plašākai sabiedrībai, atsakoties no maksimālas peļņas gūšanas uzņēmējam
        personīgi. Sociālā uzņēmējdarbība var tikt izvērsta lokāli vai
        starptautiski, tā var būt dažāda pēc preču ražošanas un pakalpojumu
        sniegšanas veida.
      </p>

      <p>
        Sīkāk lasi
        <a
          href="https://sua.lv/kas-ir-sociala-uznemejdarbiba/"
          className="pink-text-bg"
        >
          Latvijas Sociālās uzņēmējdarbības asociācijas tīmekļvietnē
        </a>
      </p>
    </div>
  );
};

export default R11content;
