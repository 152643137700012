const R6content = () => {
  return (
    <div className="desc">
      <p>
        Par dažādām līdzdalības formām lasi Latvijas Republikas Tiesībsarga
        izdotā brošūrā <br />
        <a
          href="https://www.tiesibsargs.lv/resource/2022-pilsoniska-lidzdaliba/"
          className="pink-text-bg"
        >
          Tavās rokās - pasauli kustināt!
        </a>
      </p>
       
      <p>
        Iepazīsti Vienoto tiesību aktu projektu izstrādes un saskaņošanas
        portālu jeb TAP portāls. Tā ir digitāla informācijas sistēma, kur tiek
        izstrādāti, saskaņoti un apstiprināti tiesību aktu projekti, kas virzīti
        apstiprināšanai valdībā. Šajā portālā ikviens var aktīvi iesaistīties
        tiesību aktu izstrādē – sniegt viedokli un sekot līdzi dokumentu
        virzībai. Pēcāk Ministru kabineta sēžu atklātajā daļā var sekot līdzi
        lēmumu pieņemšanas procesam – valdības sēžu tiešraidei.
      </p>
      <p>
        Izmanto digitālās demokrātijas rīkus: bezpeļņas sabiedriskā labuma
        nevalstiskās organizācijas “Sabiedrības līdzdalības Fonds” platformu{' '}
        <a href="https://manabalss.lv/" className="pink-text-bg">
          ManaBalss.lv
        </a>
        <a href="https://lemejs.lv/" className="pink-text-bg">
          Lemejs.lv
        </a>
        <a href="https://open2vote.eu/" className="pink-text-bg">
          open2vote.eu
        </a>
      </p>
    </div>
  );
};

export default R6content;
