import { useLoaderData, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import flow from '../../config/flow';
import { Header, Logos } from '../../components';
import ss2Image from '../../assets/images/ss2.png';
import ss3Image from '../../assets/images/ss3.png';

const nextRoute = (nextId) => {
  const isResult = nextId.charAt(0) === 'r';
  return isResult ? `/results/${nextId}` : `/questions/${nextId}`;
};

const QuestionPage = () => {
  const navigate = useNavigate();
  const { question } = useLoaderData();
  const { id, yes, no, a1, a2, color } = question;
  const titleId = id;
  const button1Id = !!yes ? 'yes' : `${id}.a1`;
  const button2Id = !!no ? 'no' : `${id}.a2`;
  const isGreen = color === 'green';
  const contentTextClass = isGreen ? 'text-green' : 'text-skyblue';
  const buttonClass = isGreen ? 'green-hover' : 'skyblue-hover';
  const image = isGreen ? ss2Image : ss3Image;

  const onAnswer1 = (e) => {
    const nextId = !!yes ? yes : a1;
    navigate(nextRoute(nextId));
  };

  const onAnswer2 = (e) => {
    const nextId = !!no ? no : a2;
    navigate(nextRoute(nextId));
  };

  return (
    <main>
      <div className="max-1440">
        <div className="content">
          <Header />
          <div className="card">
            <div className="mark mark-color2">?</div>
            <div className="card-content">
              <span className={`content-text ${contentTextClass}`}>
                <FormattedMessage id={titleId} />
              </span>
              <div className="buttons">
                <button 
                  className={`btn border-btn ${buttonClass}`}
                  onClick={onAnswer1}
                >
                  <FormattedMessage id={button1Id} />
                </button>
                <button
                  className={`btn border-btn ${buttonClass}`}
                  onClick={onAnswer2}
                >
                  <FormattedMessage id={button2Id} />
                </button>
              </div>
            </div>
            <div className="image">
              <img src={image} alt="" />
            </div>
          </div>
        </div>
        <Logos />
      </div>
    </main>
  );
};

export const loader = ({ params }) => {
  const { id } = params;
  const question = { ...flow[id], id };

  return { question };
};

export default QuestionPage;
