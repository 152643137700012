const R17content = () => {
  return (
    <div className="desc">
      <p>
        Par dažādām līdzdalības formām lasi Latvijas Republikas Tiesībsarga
        izdotā brošūrā <br />
        <a
          href="https://www.tiesibsargs.lv/resource/2022-pilsoniska-lidzdaliba/"
          className="pink-text-bg"
        >
          Tavās rokās - pasauli kustināt!
        </a>
      </p>
      <p>
        Izzini Padomju un komiteju lomu
        <a
          href="https://likumi.lv/ta/id/336956-pasvaldibu-likums"
          className="pink-text-bg"
        >
          Pašvaldību likumā
        </a>
      </p>
    </div>
  );
};

export default R17content;
