const env = process.env.REACT_APP_ENV;
const dev = process.env.REACT_APP_ENV === 'development';

const locale = 'lv';

const config = {
  env,
  dev,
  locale,
};

export default config;
