import { FormattedMessage } from 'react-intl';
import { Link, useLoaderData } from 'react-router-dom';

import flow from '../../config/flow';
import { Header, Logos } from '../../components';
import ss4Image from '../../assets/images/ss4.svg';
import overImage from '../../assets/images/over.png';

import R1content from './R1content';
import R2content from './R2content';
import R3content from './R3content';
import R4content from './R4content';
import R5content from './R5content';
import R6content from './R6content';
import R7content from './R7content';
import R8content from './R8content';
import R9content from './R9content';
import R10content from './R10content';
import R11content from './R11content';
import R12content from './R12content';
import R13content from './R13content';
import R14content from './R14content';
import R15content from './R15content';
import R16content from './R16content';
import R17content from './R17content';

const description = (id) => {
  return (
    <>
      {(id === 'r1' && <R1content />) ||
        (id === 'r2' && <R2content />) ||
        (id === 'r3' && <R3content />) ||
        (id === 'r4' && <R4content />) ||
        (id === 'r5' && <R5content />) ||
        (id === 'r6' && <R6content />) ||
        (id === 'r7' && <R7content />) ||
        (id === 'r8' && <R8content />) ||
        (id === 'r9' && <R9content />) ||
        (id === 'r10' && <R10content />) ||
        (id === 'r11' && <R11content />) ||
        (id === 'r12' && <R12content />) ||
        (id === 'r13' && <R13content />) ||
        (id === 'r14' && <R14content />) ||
        (id === 'r15' && <R15content />) ||
        (id === 'r16' && <R16content />) ||
        (id === 'r17' && <R17content />) || <div className="desc"></div>}
    </>
  );
};

const ResultPage = () => {
  const { result } = useLoaderData();
  const { id } = result;
  const image = ss4Image;

  return (
    <main>
      <div className="max-1440">
        <div className="content">
          <Header />
          <div className="card">
            <div className="mark mark-color4">!</div>
            <div className="card-content">
              <div className="texts">
                <span className="content-text text-pink">
                  <FormattedMessage id={id} />
                </span>
                {description(id)}
              </div>
            </div>
            <div className="image">
              <img src={image} alt="" />
            </div>
          </div>
          <div className="bottom-line">
            <span className="text">
              <FormattedMessage id="Result.info" />
            </span>
            <Link to="/" className="bottom-btn">
              <img className="over" src={overImage} alt="" />
              <FormattedMessage id="Result.startOver" />
            </Link>
         </div>
        </div>
        <Logos />
      </div>
    </main>
  );
};

export const loader = ({ params }) => {
  const { id } = params;
  const result = { ...flow[id], id };

  return { result };
};

export default ResultPage;
