const R15content = () => {
  return (
    <div className="desc">
      <p>
        Izzini
        <a
          href="https://likumi.lv/ta/id/36189-politisko-organizaciju-partiju-finansesanas-likums"
          className="pink-text-bg"
        >
          Politisko organizāciju (partiju) finansēšanas likumu
        </a>
      </p>

      <p>
        Iepazīsti
        <a
          href="https://www.knab.gov.lv/lv/partiju-finansu-avoti-un-finansesanas-ierobezojumi?utm_source=https%3A%2F%2Fwww.google.com%2F"
          className="pink-text-bg"
        >
          Partiju finanšu avotus un finansēšanas ierobežojumus Korupcijas
          novēršanas un apkarošanas biroja tīmekļvietnē
        </a>
      </p>
    </div>
  );
};

export default R15content;
