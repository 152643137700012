const R3content = () => {
  return (
    <div className="desc">
      <p>
        Lobists - lielas firmas, citas valsts politiska, ekonomiska vai
        finansiāla grupējuma pārstāvis (aģents), kas mēģina pierunāt vēlētus
        politiķus (piem., deputātus) vai ieceltus ierēdņus atbalstīt vai kavēt
        kādus centienus (tezaurs.lv). 
      </p>

      <p>
        Sīkāk par lobēšanu lasi
        <a
          href="https://enciklopedija.lv/skirklis/33730"
          className="pink-text-bg"
        >
          Nacionālās enciklopēdijas šķirklī lobēšana
        </a>
      </p>
      <p>
        Iepazīsti
        <a
          href="https://likumi.lv/ta/id/336676-interesu-parstavibas-atklatibas-likums"
          className="pink-text-bg"
        >
          Interešu pārstāvības atklātības likumu 
        </a>
      </p>
    </div>
  );
};

export default R3content;
