import { createBrowserRouter } from 'react-router-dom';

import {
  ErrorPage,
  StartPage,
  QuestionPage,
  loaderQuestionPage,
  ResultPage,
  loaderResultPage,
} from '../pages';

const router = createBrowserRouter([
  {
    path: '/',
    element: <StartPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'questions/:id',
    loader: loaderQuestionPage,
    element: <QuestionPage />,
  },
  {
    path: 'results/:id',
    loader: loaderResultPage,
    element: <ResultPage />,
  },
]);

export default router;
