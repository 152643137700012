import { FormattedMessage } from 'react-intl';

import star from '../../assets/images/star.png';

const Header = () => {
  return (
    <div className="tag">
      <img src={star} alt="" />
      <span className="tag-text">
        <FormattedMessage id="Header.title" />
      </span>
      <img src={star} alt="" />
    </div>
  );
};

export default Header;
