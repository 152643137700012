const R14content = () => {
  return (
    <div className="desc">
      <p>
        Izzini politisko partiju un to apvienību darbības tiesiskos pamatus
        <a
          href="https://likumi.lv/ta/id/139367-politisko-partiju-likums"
          className="pink-text-bg"
        >
          Politisko partiju likumā
        </a>
      </p>
    </div>
  );
};

export default R14content;
