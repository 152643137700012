const R16content = () => {
  return (
    <div className="desc">
      <p>
        Iepazīsti
        <a
          href="https://likumi.lv/ta/id/10944-valsts-civildienesta-likums"
          className="pink-text-bg"
        >
          Valsts civildienesta likumu
        </a>
        , kas nosaka valsts civildienesta ierēdņa amata pretendentam izvirzāmās
        obligātās prasības, iecelšanu ierēdņa amatā un atbrīvošanu no ierēdņa
        amata, ierēdņa pienākumus, tiesības, dienesta gaitu un vadību vispārējā
        valsts civildienestā.
      </p>
    </div>
  );
};

export default R16content;
