const R12content = () => {
  return (
    <div className="desc">
      <p>
        Par dažādām līdzdalības formām lasi Latvijas Republikas Tiesībsarga
        izdotā brošūrā <br />
        <a
          href="https://www.tiesibsargs.lv/resource/2022-pilsoniska-lidzdaliba/"
          className="pink-text-bg"
        >
          Tavās rokās - pasauli kustināt!
        </a>
      </p>
      <p>
        <a
          href="https://likumi.lv/ta/id/57980-latvijas-republikas-satversme"
          className="pink-text-bg"
        >
          Latvijas Republikas Satversmē
        </a>
        ir noteikts: 102. Ikvienam ir tiesības apvienoties biedrībās, politiskās
        partijās un citās sabiedriskās organizācijās.
      </p>
    </div>
  );
};

export default R12content;
