const R13content = () => {
  return (
    <div className="desc">
      <p>
        Par dažādām līdzdalības formām lasi Latvijas Republikas Tiesībsarga
        izdotā brošūrā <br />
        <a
          href="https://www.tiesibsargs.lv/resource/2022-pilsoniska-lidzdaliba/"
          className="pink-text-bg"
        >
          Tavās rokās - pasauli kustināt!
        </a>
      </p>
      <p>
        Izzini politisko partiju un to apvienību darbības tiesiskos pamatus
        <a
          href="https://likumi.lv/ta/id/139367-politisko-partiju-likums"
          className="pink-text-bg"
        >
          Politisko partiju likumā
        </a>
      </p>
    </div>
  );
};

export default R13content;
