const R1content = () => {
  return (
    <div className="desc">
      <p>
        Sēdēšana pie TV nav aktīva pilsoniska līdzdalība. Piedalies nozīmīgu
        lēmumu, procesu līdzveidošanā, apspriešanā, pieņemšanā un īstenošanā!
      </p>
      <p>
        Iepazīsti
        <a
          href="https://likumi.lv/ta/id/329302-par-saliedetas-un-pilsoniski-aktivas-sabiedribas-attistibas-planu-2022-2023-gadam"
          className="pink-text-bg"
        >
          Saliedētas un pilsoniski aktīvas sabiedrības attīstības plānu
          2022.-2023.gadam
        </a>
      </p>
      <p>
        Izzini{' '}
        <a
          href="https://likumi.lv/ta/id/336956-pasvaldibu-likums"
          className="pink-text-bg"
        >
          Pašvaldības likumu
        </a>
        , kurā noteiktas jaunas līdzdalības formas (sākot no 16 gadu vecuma)
      </p>
      <p>
        Iepazīsti Vienoto tiesību aktu projektu izstrādes un saskaņošanas
        portālu jeb TAP portāls. Tā ir digitāla informācijas sistēma, kur tiek
        izstrādāti, saskaņoti un apstiprināti tiesību aktu projekti, kas virzīti
        apstiprināšanai valdībā. Šajā portālā ikviens var aktīvi iesaistīties
        tiesību aktu izstrādē – sniegt viedokli un sekot līdzi dokumentu
        virzībai. Pēcāk Ministru kabineta sēžu atklātajā daļā var sekot līdzi
        lēmumu pieņemšanas procesam – valdības sēžu tiešraidei.
      </p>
      <p>
        Izmanto digitālās demokrātijas rīkus: bezpeļņas sabiedriskā labuma
        nevalstiskās organizācijas “Sabiedrības līdzdalības Fonds” platformu
        <a href="https://manabalss.lv/" className="pink-text-bg">
          ManaBalss.lv
        </a>
        <a href="https://lemejs.lv/" className="pink-text-bg">
          Lemejs.lv
        </a>
        <a href="https://open2vote.eu/" className="pink-text-bg">
          open2vote.eu
        </a>
      </p>
    </div>
  );
};

export default R1content;
