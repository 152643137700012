import config from './config';
import { configureStore } from '@reduxjs/toolkit';

const preloadedState = {};

const store = configureStore({
  reducer: {},
  devTools: config.dev,
  preloadedState,
});

export default store;
