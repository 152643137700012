import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import flow from '../../config/flow';
import { Header, Logos } from '../../components';
import ss1Image from '../../assets/images/ss1.svg';
// import shareImage from '../../assets/images/share.png';

const StartPage = () => {
  const navigate = useNavigate();
  const cell = flow.start;

  const onYes = (ev) => {
    navigate(`/questions/${cell.yes}`);
  };

  const onNo = (ev) => {
    navigate(`/questions/${cell.no}`);
  };

  return (
    <main>
      <div className="max-1440">
        <div className="content">
          <Header />
          <div className="card">
            <div className="mark mark-color1">?</div>
            <div className="card-content">
              <span className="content-text text-blue">
                <FormattedMessage id="start" />
              </span>
              <div className="buttons">
                <button className="btn border-btn blue-hover" onClick={onYes}>
                  <FormattedMessage id="yes" />
                </button>
                <button className="btn border-btn blue-hover" onClick={onNo}>
                  <FormattedMessage id="no" />
                </button>
              </div>
            </div>
            <div className="image">
              <img src={ss1Image} alt="" />
            </div>
          </div>
          <div className="bottom-line">
            <span className="text">
              <FormattedMessage id="StartPage.description" />
            </span>
            {/* <button className="bottom-btn">
              <FormattedMessage id="StartPage.about" />
              <img src={shareImage} alt="" />
            </button> */}
          </div>
        </div>
        <Logos />
      </div>
    </main>
  );
};
export default StartPage;
