const R8content = () => {
  return (
    <div className="desc">
      <p>
        Demonstrācija - masu sabiedriskā noskaņojuma, protesta, prasību
        izpausmei rīkoti masveida gājieni, mītiņi u. tml. (tezaurs.lv)
      </p>
      <p>
        Par dažādām līdzdalības formām lasi Latvijas Republikas Tiesībsarga
        izdotā brošūrā <br />
        <a
          href="https://www.tiesibsargs.lv/resource/2022-pilsoniska-lidzdaliba/"
          className="pink-text-bg"
        >
          Tavās rokās - pasauli kustināt!
        </a>
      </p>
    </div>
  );
};

export default R8content;
