const R10content = () => {
  return (
    <div className="desc">
      <p>
        Balso par Līdzdalības budžetēšanas idejām savā pašvaldībā! Izzini
        līdzdalīgo budžetēšanu
        <a
          href="https://likumi.lv/ta/id/336956-pasvaldibu-likums"
          className="pink-text-bg"
        >
          Pašvaldību likumā
        </a>
      </p>
    </div>
  );
};

export default R10content;
