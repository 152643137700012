const R9content = () => {
  return (
    <div className="desc">
      <p>
        Uzzini
        <a
          href="https://www.cvk.lv/lv/darbs-velesanu-iecirkni"
          className="pink-text-bg"
        >
          par darbu Vēlēšanu iecirknī
        </a>
      </p>
    </div>
  );
};

export default R9content;
