const R5content = () => {
  return (
    <div className="desc">
      <p>
        Politoloģija, arī politikas zinātne, pēta sabiedrības politisko dzīvi.
        Latviešu valodā abi nosaukumi uzskatāmi par sinonīmiem. Sīkāk par
        politiloģiju lasi
        <a
          href="https://enciklopedija.lv/skirklis/8486-politolo%C4%A3ija-Latvij%C4%81"
          className="pink-text-bg"
        >
          Nacionālās enciklopēdijas šķirklī politoloģija Latvijā
        </a>
      </p>
    </div>
  );
};

export default R5content;
